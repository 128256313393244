import React from 'react'
import PropTypes from 'prop-types' 
import { graphql, navigate }  from 'gatsby'

import Img from '../components/AtomImg'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'

// import { Container } from '../components/styles/Layout.styles'
import H2 from '../components/H2'
import Body2 from '../components/Body2'
import Div from '../components/Div/Div.styles'
import Button from '../components/Button/Button'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import { h2Style, titleStyle } from '../components/styles/InternalPage.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[34.5, 35, 68, 46]}
            mb={[50]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H2
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={titleStyle}
                >
                  Nossos Produtos
                </H2>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  order={[2, 2, 1]}
                  mb={[0, 0, 3]}
                  maxWidth={['90%', 422, 680, 680]}
                >
                  Conheça nossos serviços multi redundantes em conectividade, climatização, segurança física e disponibilidade, e escolha a melhor solução para o seu negócio.
                </Body2>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />  
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[40, 50, 60, 90]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 395, 489]}
              >
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Cloud Server<br />HostDime
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  maxWidth={['323px', 416]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Servidores em nuvem flexíveis, que ajustam automaticamente a CPU, RAM e o espaço de disco sob demanda, apresentando alta disponibilidade e failover automático. Aproveite nosso hardware de ponta, incluindo processadores de nível empresarial e os melhores SSDs da indústria para criar sua própria estrutura instantânea de negócios.
                </Body2>
                <Button
                  onClick={() => navigate('/cloud-server')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                  }}
                >
                  Explorar opções de Nuvem
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[20, 25, 30, 45]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 357, 416]}
              >
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Servidores<br />Dedicados
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', 361, 469]}
                >
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Hardwares fisicamente isolados, de uso exclusivo da sua empresa, trazendo assim, maior segurança e desempenho, além de contar com monitoramento proativo 24x7x365.
                </Body2>                
                <Button
                  onClick={() => navigate('/servidores-dedicados')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  configurações personalizadas
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mt={[20, 25, 30, 45]}
            mb={[40, 50, 60, 90]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 348, 418]}
              >
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Colocation<br />HostDime
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', '323px', 348, 418]}
                >
                  <Img
                    fluid={data.image4.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Abrigue seu parque de ativos de TI em nossas estruturas certificadas e garanta a continuidade do seu negócio.
                </Body2>
                <Button
                  onClick={() => navigate('/colocation')}
                  schema='white'
                  outlined
                  selected
                  variant='base'
                  on='onSurface'
                  width={['100%']}
                  maxWidth={['328px']}
                  style={{
                    display: 'flex',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    margin: '0 auto'
                  }}
                >
                  saiba mais
                </Button>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />        
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50]}
            pb={[80]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
                style={{
                  marginBottom: 30
                }}
              >
                <H2
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={titleStyle}
                >
                  Outros Produtos
                </H2>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  maxWidth={['90%', 422, 504, 504]}
                >
                  Somos cada vez mais digitais. Independente do setor, é preciso pensar na segurança dos dados críticos para a empresa e seus clientes. Por isso, nosso serviço é personalizado, na medida do que você necessita.
                </Body2>
              </Div>
              <Button
                onClick={() => navigate('/outros-produtos')}
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  margin: '0 auto'
                }}
              >
                encontre aqui
              </Button>
            </Column>
          </Row>
        </Div>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nosso Suporte' />
      <PageTemplate
        title='Nosso Suporte'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
   image1: file(relativePath: { eq: "support/nosso-suporte.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image2: file(relativePath: { eq: "home/cloud-server.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image3: file(relativePath: { eq: "home/servidor-dedicado.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image4: file(relativePath: { eq: "home/hostdime-colocation.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default StaticPage
